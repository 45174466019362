<template>
    <zw-sidebar @shown="shown" :title="this.payload.id ? $t('common.form.title.editLanguage')  + ' ('+ this.payload.id + ')' : $t('common.form.title.createLanguage')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="12">
                        <zw-input-group v-model="form.code"
                                        name="code"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>

                    <b-col cols="12">
                        <zw-input-group v-model="form.name"
                                        name="name"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'LanguageModal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                code: '',
                name: '',
            },
            form: {},
            labelPrefix: 'settings.language.label.',
        }
    },
    methods: {
        ...mapGetters('I18NStore', ['getLanguage']),
        shown() {
            const language = this.$store.dispatch('I18NStore/fetchLanguage', this.payload.id)

            Promise.all([language])
                .then(() => {
                    this.form = {
                        ...this.defaultForm,
                        ...JSON.parse(JSON.stringify(this.getLanguage()))
                    }
                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true

                    this.$store.dispatch('I18NStore/saveLanguage', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>